@import "../_app.scss";

@import '../main.scss';

html {
  background: #fff;
}

body {
  font-family: Helvetica,Arial,sans-serif;
}

.wrapperContainer {
  width: 1025px;
}

/**/

#header {
  padding: 5px 0;
  border-top: 3px solid #07b161;

  .companyLogo {
    width: 215px;
    height: 44px;
    margin-top: 25px;
    position: relative;
    margin-bottom: 40px;
    background: image-url('_yesbackup/logo-header.png') no-repeat 0 0;
  }
  .menu
  {
    top: 40px;
    position: relative;
  }
}

#footer .logo {
  width: 215px;
  float: right;
  margin-top: 50px;
  height: 44px;
  background: image-url('_yesbackup/logo-header.png') no-repeat 0 0;
}

#header .menu a {
  padding: 10px;
  text-transform: uppercase;
  font-family: open-sans, arial, sans-serif;
  font-size: 14px;
  color: #7a7a7a;
}

/**/

#content {
  border-top: 1px solid #dfdfdf;
  padding: 40px 0 50px;
}

/**/

#footer {
  background: #fff image-url('_yesbackup/bg-footer.png') repeat-x 0 0;
}

.greybg {
  background-color: #f0f0f0 !important;
}

/**/

#bottomdiv {
  padding: 30px 0;
}

#baseFooter {
  padding: 0;
  float: right;
  width: 30%;
  border: none;
  margin: 0;
}

.brandColor {
  color: #07b161;
}

a {
  color: #07b161;

  &:link, &:visited {
    color: #07b161;
  }
}

.darkGrey, h1, h2, h3 {
  color: #4d4d4d;
}

.greyColor {
  color: #7a7a7a;
}

.btn {
  height: 45px;
  font-family: arial, sans-serif;
  font-size: 14px;
  color: #fff;
  background: #07b161;
  border-radius: 5px;
  float: none;
  display: inline-block;

  > * {
    height: 45px;
    padding: 0 20px;
    background-color: transparent;
    line-height: 46px;
    text-transform: uppercase;
    font-family: arial,sans-serif;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    float: none;
    text-align: center;
  }

  i {
    margin-right: 10px;
  }
}

.fullwidthbtn input {
  margin-left: 15%;
}

i {
  &[class^="icon-small"] {
    background: image-url('_yesbackup/iconsmall.png') no-repeat right 0;
    display: inline-block;
  }

  &.icon-small-arrowr {
    padding: 0;
    height: 19px;
    width: 20px;
    background-position: -7px -68px;
  }

  &.icon-small-tickgr {
    padding: 0;
    background-position: 0 -8px;
    height: 18px;
    width: 30px;
    margin-right: 10px;
  }

  &[class^="icon-large"] {
    background: image-url('_yesbackup/iconsbig.png') no-repeat right 0;
    display: inline-block;
  }

  &.icon-large-files {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0 0;
  }

  &.icon-large-folderup {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0px -105px;
  }

  &.icon-large-tabletdown {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0px -210px;
  }

  &.icon-large-clock {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0px -315px;
  }

  &.icon-large-laptop {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0px -420px;
  }

  &.icon-large-deny {
    padding: 0;
    height: 105px;
    width: 105px;
    background-position: 0px -525px;
  }

  &[class^="icon-medium"] {
    display: inline-block;
    background: image-url('_yesbackup/iconsmedium.png') no-repeat right 0;
  }

  &.icon-medium-lock {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0 0;
  }

  &.icon-medium-cloudupload {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0px -60px;
  }

  &.icon-medium-key {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0px -120px;
  }

  &.icon-medium-chat {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0px -180px;
  }

  &.icon-medium-slidenavleft {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0px -240px;
  }

  &.icon-medium-slidenavright {
    padding: 0;
    height: 60px;
    width: 60px;
    background-position: 0px -300px;
  }
}

.watermark label {
  padding-left: 5px;
}

.inputWrapper {
  input {
    &[type="text"], &[type="email"], &[type="password"] {
      height: 26px;
      line-height: 26px;
      padding: 8px;
      font-size: 15px;
      box-shadow: none;
    }
  }

  textarea {
    height: 26px;
    line-height: 26px;
    padding: 8px;
    font-size: 15px;
    box-shadow: none;
  }

  select {
    padding: 8px;
    font-size: 15px;
    box-shadow: none;
    height: 36px;
    line-height: 36px;
  }

  label {
    height: 27px;
    line-height: 27px;
    font-size: 15px;
  }
}

#heroBanner .createAccount h2, #lander .createAccount h2, #content .pageIntro .createAccount h2 {
  padding: 15px 20px;
  background: #07b161;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 0 14px;
  text-align: center;
  text-transform: capitalize;
  font-size: 23px;
  color: #fff;
}

.title {
  &.large {
    font-size: 30px;
    font-weight: normal;
    font-family: Helvetica, arial, sans-serif;
    margin: 20px auto 15px auto;
    text-align: center;
  }

  font-size: 30px;
  font-weight: normal;
  font-family: Helvetica, arial, sans-serif;
  margin: 20px auto 15px auto;
  text-align: center;

  &.small {
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 15px;
  }
}

.heading {
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 15px;
}

.subtitle {
  color: #737373;
  font-size: 17px;
  text-align: center;
}

.pageIntro {
  margin-bottom: 20px;
}

#footer {
  background: #302e30;
  color: #727273;

  #footer-inner {
    padding: 0;
    margin: 0;
  }
}

.feature {
  float: left;
  max-width: 40%;
  margin: 40px 0 0;

  &:nth-child(even) {
    float: right;
  }

  i[class^="icon-medium"] {
    float: left;
    margin-right: 25px;
    margin-bottom: 30px;
  }

  h4 {
    text-align: left;
    margin-top: 0;
  }

  p {}
}
